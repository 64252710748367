import React from 'react';
import PropTypes from 'prop-types';
import { SectionCard } from './index.style';
import { SectionDescription } from '../SectionDescription';
import { SectionPercent } from '../SectionPercent';

export const SectionItem = ({
  percent,
  description,
  displayStyles,
  visualStyles,
  isFirstItem,
  textAlignment,
  ...rest
}) => (
  <SectionCard
    isFirstItem={isFirstItem}
    visualStyles={visualStyles}
    displayStyles={displayStyles}
    textAlignment={textAlignment}
  >
    {percent && (
      <SectionPercent
        {...rest}
        content={percent}
        visualStyles={visualStyles}
        displayStyles={displayStyles}
      />
    )}
    <SectionDescription
      content={description}
      visualStyles={visualStyles}
      displayStyles={displayStyles}
    />
  </SectionCard>
);
SectionItem.propTypes = {
  percent: PropTypes.string,
  description: PropTypes.string,
  textAlignment: PropTypes.string,
};
