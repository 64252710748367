import React from 'react';
import PropTypes from 'prop-types';
import { Header, ItemRow } from '../standard/index.style';
import { SectionItem } from '../../innerComponents';
import { splitListIntoChunks } from '../../../../../../helpers';
import {
  CallToAction,
  StyledHeading,
  StyledParagraph,
} from '../../../sharedComponents';
import styled from 'styled-components';
import {
  getStyledPropertyValue,
  renderStyledElementStyles,
} from '../../../../helpers';

export const SectionWrapper = styled('div')`
  ${({ subsectionLenght }) => {
    return `
      flex-basis: calc(100% / ${subsectionLenght});
      padding: 0 10px;
    `;
  }}

  ${({ theme, displayStyles }) =>
    renderStyledElementStyles(theme, displayStyles?.elements?.itemWrapper)}
`;

// Override call to action styles according by design
export const StyledCallToAction = styled(CallToAction)`
  ${({ theme, displayStyles }) =>
    renderStyledElementStyles(theme, displayStyles?.elements?.callToAction)}
  background-color: ${({ theme, textStyles }) =>
    getStyledPropertyValue(theme, textStyles?.color)} !important;
  color: ${({ theme, backgroundStyle }) =>
    getStyledPropertyValue(theme, backgroundStyle?.backgroundColor)} !important;
  font-weight: bold !important;
`;

export function StatisticsThin({
  section,
  visualStyles,
  displayStyles,
  textStyles,
  backgroundStyle,
  textAlignment,
}) {
  const {
    title,
    titleSize,
    subtitle,
    link: button = {},
    subsections = [],
  } = section;

  return (
    <>
      <div className="container">
        <div className="row">
          {(subtitle || title) && (
            <Header
              className="col-xs-12 center-xs"
              visualStyles={visualStyles}
              displayStyles={displayStyles}
              hasOnlySubtitle={!title}
            >
              {title && (
                <StyledHeading
                  content={title}
                  as={titleSize}
                  visualStyles={visualStyles}
                  displayStyles={displayStyles}
                />
              )}
              {subtitle && (
                <StyledParagraph
                  content={subtitle}
                  displayStyles={displayStyles}
                  visualStyles={visualStyles}
                />
              )}
            </Header>
          )}
        </div>
        {splitListIntoChunks(subsections, 5).map(
          (subsectionRange, rangeIndex) => (
            <ItemRow key={rangeIndex} className="row">
              {subsectionRange
                .filter((item) => (item.animated ? item.title : true))
                .map(({ title: percent, body, ...rest }, index) => (
                  <SectionWrapper
                    key={index}
                    subsectionLenght={subsectionRange.length}
                    displayStyles={displayStyles}
                  >
                    <SectionItem
                      percent={percent}
                      description={body}
                      visualStyles={visualStyles.elements.items}
                      displayStyles={displayStyles.elements.items}
                      textAlignment={textAlignment}
                      {...rest}
                    />
                  </SectionWrapper>
                ))}
            </ItemRow>
          ),
        )}
        {button.type && (
          <div className="center-xs">
            <StyledCallToAction
              ctaConfig={button}
              ctaMode={button.mode}
              displayStyles={displayStyles}
              textStyles={textStyles}
              backgroundStyle={backgroundStyle}
            />
          </div>
        )}
      </div>
    </>
  );
}

StatisticsThin.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string,
    textAlignment: PropTypes.string,
    titleSize: PropTypes.string,
    subtitle: PropTypes.string,
    link: PropTypes.object,
    subsections: PropTypes.array,
  }).isRequired,
};

export default StatisticsThin;
