import React from 'react';
import CountUp from 'react-countup';
import { useAnimatedCounter } from '../../../../../../../hooks/useAnimatedCounter';
import { SubsectionItemType } from '../index';
import styles from './SubsectionItem.module.scss';
import { SubsectionTitle } from '../SubsectionTitle';
import { SubsectionContent } from '../SubsectionContent';

export default function SubsectionItem(props: SubsectionItemType) {
  const { title, titlePrefix, titleSuffix, body } = props;
  const {
    startAnimation,
    endAnimation,
    shouldRenderAnimatedCounter,
    separator,
  } = useAnimatedCounter(props);

  return (
    <div className={styles.item}>
      {title && (
        <SubsectionTitle>
          {titlePrefix && <span className={styles.prefix}>{titlePrefix}</span>}
          {shouldRenderAnimatedCounter ? (
            <CountUp
              start={startAnimation}
              end={endAnimation}
              separator={separator}
              enableScrollSpy
            />
          ) : (
            title
          )}
          {titleSuffix && <span className={styles.suffix}>{titleSuffix}</span>}
        </SubsectionTitle>
      )}
      {body && <SubsectionContent>{body}</SubsectionContent>}
    </div>
  );
}
