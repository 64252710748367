import toNumber from 'lodash/toNumber';
import isNumber from 'lodash/isNumber';
import { HubStatisticModuleSectionNumberFormat } from '../../components/hub/shared/siteBuilder/modules/StatisticsModule';

export type AnimatedCounterParams = {
  title: string;
  animated: boolean;
  countUpFrom: string;
  numberFormat: HubStatisticModuleSectionNumberFormat;
};

export function defineSeparatorFormat(
  numberFormat: HubStatisticModuleSectionNumberFormat,
) {
  if (numberFormat === HubStatisticModuleSectionNumberFormat.COMMA) return ',';
  if (numberFormat === HubStatisticModuleSectionNumberFormat.DOT) return '.';
  if (numberFormat === HubStatisticModuleSectionNumberFormat.SPACE) return ' ';
  return '';
}

export function useAnimatedCounter(params: AnimatedCounterParams) {
  const { animated, title, countUpFrom, numberFormat } = params;
  const numericTitle = toNumber(title);
  const shouldRenderAnimatedCounter =
    animated && !isNaN(numericTitle) && isNumber(numericTitle);
  const startAnimation = toNumber(countUpFrom);
  const separator = defineSeparatorFormat(numberFormat);

  return {
    shouldRenderAnimatedCounter,
    startAnimation,
    endAnimation: numericTitle || 0,
    separator,
  };
}
