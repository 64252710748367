import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';
import CountUp from 'react-countup';
import { useAnimatedCounter } from '../../../../../../hooks/useAnimatedCounter';

const Wrapper = styled.div`
  ${({ theme, visualStyles }) =>
    renderStyledElementStyles(theme, visualStyles.elements.heading)};
  ${({ theme, displayStyles }) =>
    renderStyledElementStyles(theme, displayStyles.elements.heading)};
`;

const PercentText = styled.h5`
  ${({ theme }) =>
    theme.globalVariables.isRTL &&
    css`
      direction: ltr;
      text-align: right;
    `}
`;

const Prefix = styled.span`
  padding-right: 4px;

  ${({ theme }) =>
    theme.globalVariables.isRTL &&
    css`
      padding-right: 0;
      padding-left: 4px;
    `}
`;

const Suffix = styled.span`
  padding-left: 4px;

  ${({ theme }) =>
    theme.globalVariables.isRTL &&
    css`
      padding-left: 0;
      padding-right: 4px;
    `}
`;

export const SectionPercent = ({
  animated,
  content,
  visualStyles,
  displayStyles,
  titlePrefix,
  titleSuffix,
  countUpFrom,
  numberFormat,
}) => {
  const {
    startAnimation,
    endAnimation,
    shouldRenderAnimatedCounter,
    separator,
  } = useAnimatedCounter({
    animated,
    title: content,
    countUpFrom,
    numberFormat,
  });

  return (
    <Wrapper displayStyles={displayStyles} visualStyles={visualStyles}>
      <PercentText>
        {titlePrefix && <Prefix>{titlePrefix}</Prefix>}
        {shouldRenderAnimatedCounter ? (
          <CountUp
            start={startAnimation}
            end={endAnimation}
            separator={separator}
            enableScrollSpy
          >
            {({ countUpRef }) => <span ref={countUpRef} />}
          </CountUp>
        ) : (
          content
        )}
        {titleSuffix && <Suffix>{titleSuffix}</Suffix>}
      </PercentText>
    </Wrapper>
  );
};

SectionPercent.defaultProps = {
  countUpFrom: '0',
};

SectionPercent.propTypes = {
  content: PropTypes.string,
};
