import React from 'react';
import PropTypes from 'prop-types';
import { BackgroundImage, Header, ItemRow } from './index.style';
import { SectionItem } from '../../innerComponents';
import { splitListIntoChunks } from '../../../../../../helpers';
import {
  StyledCallToAction,
  StyledHeading,
  StyledParagraph,
} from '../../../sharedComponents';

export function StatisticsStandard({ section, visualStyles, displayStyles }) {
  const {
    title,
    titleSize,
    subtitle,
    link: button = {},
    subsections = [],
  } = section;

  return (
    <>
      <BackgroundImage className="lazy" visualStyles={visualStyles} />
      <div className="container">
        <div className="row">
          {(subtitle || title) && (
            <Header
              className="col-xs-12"
              visualStyles={visualStyles}
              displayStyles={displayStyles}
              hasOnlySubtitle={!title}
            >
              {title && (
                <StyledHeading
                  content={title}
                  as={titleSize}
                  visualStyles={visualStyles}
                  displayStyles={displayStyles}
                />
              )}
              {subtitle && (
                <StyledParagraph
                  content={subtitle}
                  displayStyles={displayStyles}
                  visualStyles={visualStyles}
                />
              )}
            </Header>
          )}
        </div>
        {splitListIntoChunks(subsections, 3).map(
          (subsectionRange, rangeIndex) => (
            <ItemRow
              key={rangeIndex}
              className="row"
              displayStyles={displayStyles}
              isFirstRow={rangeIndex === 0}
            >
              {subsectionRange
                .filter((item) => (item.animated ? item.title : true))
                .map(({ title: percent, body, ...rest }, i) => (
                  <div key={i} className="col-xs-12 col-sm-4">
                    <SectionItem
                      isFirstItem={rangeIndex === 0 && i === 0}
                      percent={percent}
                      description={body}
                      visualStyles={visualStyles.elements.items}
                      displayStyles={displayStyles.elements.items}
                      {...rest}
                    />
                  </div>
                ))}
            </ItemRow>
          ),
        )}
        {button.type && (
          <StyledCallToAction
            ctaConfig={button}
            ctaMode={button.mode}
            displayStyles={displayStyles}
          />
        )}
      </div>
    </>
  );
}

StatisticsStandard.propTypes = {
  section: PropTypes.shape({
    titleSize: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    link: PropTypes.object,
    subsections: PropTypes.array,
  }).isRequired,
};

export default StatisticsStandard;
