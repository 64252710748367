import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

const Wrapper = styled.div`
  ${({ theme, visualStyles }) =>
    renderStyledElementStyles(theme, visualStyles.elements.paragraph)};
  ${({ theme, displayStyles }) =>
    renderStyledElementStyles(theme, displayStyles.elements.paragraph)};
`;

export const SectionDescription = ({
  content,
  visualStyles,
  displayStyles,
}) => (
  <Wrapper displayStyles={displayStyles} visualStyles={visualStyles}>
    <p>{content}</p>
  </Wrapper>
);

SectionDescription.propTypes = {
  content: PropTypes.string,
};
