import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { SiteBuilderModule } from '../sharedComponents';
import { StatisticsStandard, StatisticsThin } from './displayStyles';
import styled, { ThemeContext } from 'styled-components';
import { renderStyledElementStyles } from '../../helpers';

const Wrapper = styled.div`
  position: relative;
  ${({ theme, statisticsModule }) =>
    renderStyledElementStyles(theme, statisticsModule)};

  ${({ theme, backgroundStyle }) =>
    renderStyledElementStyles(theme, backgroundStyle)};

  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    ${({ theme, textStyles }) => {
      return renderStyledElementStyles(theme, textStyles);
    }}
  }
`;

const StatisticsModule = ({ section }) => {
  const { displayStyle, textColour, backgroundColour, textAlignment } = section;
  const themeConfig = useContext(ThemeContext);

  const { statisticsModule } = themeConfig.siteBuilderModules;
  const statisticModuleDisplayStyle =
    statisticsModule.visualStyles[displayStyle];
  const backgroundStyle = statisticModuleDisplayStyle[backgroundColour];
  const textStyles = statisticModuleDisplayStyle?.textStyles
    ? statisticModuleDisplayStyle?.textStyles[textColour]
    : {};
  const visualStyles = statisticsModule.visualStyles[displayStyle];
  const displayStyles = statisticsModule.displayStyles[displayStyle];

  return (
    <SiteBuilderModule section={section}>
      <Wrapper
        statisticsModule={statisticModuleDisplayStyle}
        backgroundStyle={backgroundStyle}
        textStyles={textStyles}
      >
        {displayStyle === 'STANDARD' && (
          <StatisticsStandard
            section={section}
            visualStyles={visualStyles}
            displayStyles={displayStyles}
          />
        )}
        {displayStyle === 'THIN' && (
          <StatisticsThin
            section={section}
            visualStyles={visualStyles}
            displayStyles={displayStyles}
            textStyles={textStyles}
            backgroundStyle={backgroundStyle}
            textAlignment={textAlignment}
          />
        )}
      </Wrapper>
    </SiteBuilderModule>
  );
};

StatisticsModule.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string,
    titleSize: PropTypes.string,
    subtitle: PropTypes.string,
    displayStyle: PropTypes.string,
    textColour: PropTypes.string,
    backgroundColour: PropTypes.string,
    link: PropTypes.object,
    subsections: PropTypes.array,
  }).isRequired,
};

export default StatisticsModule;
