import styled, { css } from 'styled-components';
import { devices, renderStyledElementStyles } from '../../../../helpers';

export const SectionCard = styled.div`
  ${({ theme, displayStyles }) =>
    renderStyledElementStyles(theme, displayStyles.elements.card)};
  ${({ theme, displayStyles, textAlignment }) =>
    renderStyledElementStyles(
      theme,
      displayStyles.elements.card[textAlignment],
    )};
  ${({ theme, visualStyles }) =>
    renderStyledElementStyles(theme, visualStyles.elements.card)};
  ${({ isFirstItem }) =>
    isFirstItem &&
    css`
      margin-top: 0;
      @media only screen and (min-width: ${devices.breakpoints.tablet}) {
        margin-top: 0;
      }
    `}
`;
