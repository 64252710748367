import styled, { css } from 'styled-components';
import { devices, renderStyledElementStyles } from '../../../../helpers';

export const Header = styled.div`
  ${({ theme, displayStyles }) =>
    renderStyledElementStyles(theme, displayStyles?.elements?.header)};
  ${({ theme, visualStyles }) =>
    renderStyledElementStyles(theme, visualStyles?.elements?.header)};

  ${({ theme, displayStyles, hasOnlySubtitle }) =>
    hasOnlySubtitle &&
    renderStyledElementStyles(
      theme,
      displayStyles?.elements?.headerHasOnlySubtitle,
    )};
  ${({ theme, visualStyles, hasOnlySubtitle }) =>
    hasOnlySubtitle &&
    renderStyledElementStyles(
      theme,
      visualStyles?.elements?.headerHasOnlySubtitle,
    )};
`;

export const BackgroundImage = styled.div`
  background-attachment: fixed;
  content: '';
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0.25;
  background-size: 80px;
  ${({ theme, visualStyles }) =>
    renderStyledElementStyles(theme, visualStyles.elements.backgroundImage)};
`;

export const ItemRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${({ theme, displayStyles }) =>
    renderStyledElementStyles(
      theme,
      displayStyles?.elements?.items?.elements?.row,
    )};
  ${({ isFirstRow }) =>
    isFirstRow &&
    css`
      margin-top: 0;
      @media only screen and (min-width: ${devices.breakpoints.tablet}) {
        margin-top: 0;
      }
    `}
`;
